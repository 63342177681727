
import { Vue, Component, Watch, ProvideReactive, Prop } from 'vue-property-decorator'
import BookingHeader from '@/components/BookingHeader.vue'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import ReservationDetailTripSummary from '@/components/ReservationDetailTripSummary.vue'
import ReservationCard from '@/components/ReservationCard.vue'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import SkeletonBidCard from '@/components/SkeletonBidCard.vue'
import PaymentSuccessDialog from '@/components/PaymentSuccessDialog.vue'
import ReservationDetailDriverInfo from '@/components/ReservationDetailDriverInfo.vue'
import PayRemainingBalanceDialog from '@/components/PayRemainingBalanceDialog.vue'
import ReservationDetailContactInfo from '@/components/ReservationDetailContactInfo.vue'
import ReservationDetailReview from '@/components/ReservationDetailReview.vue'
import ReservationDetailWarningSummary from '@/components/ReservationDetailWarningSummary.vue'
import ReservationDetailModificationRequested from '@/components/ReservationDetailModificationRequested.vue'
import ReservationDetailPostConversionDialog from '@/components/ReservationDetailPostConversionDialog.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import ReservationDetailDocuments from '@/components/ReservationDetailDocuments.vue'
import ReservationDetailSupport from '@/components/ReservationDetailSupport.vue'
import ReservationDetailPaymentOverview from '@/components/ReservationDetailPaymentOverview.vue'
import PhoneOTCVerificationDialog from '@/components/PhoneOTCVerificationDialog.vue'
import FlightInformationDialog from '@/components/FlightInformationDialog.vue'
import ReservationDetailCancellationInfo from '@/components/ReservationDetailCancellationInfo.vue'
import CharterUPValueProps from '@/components/CharterUPValueProps.vue'
import TripModificationWizard from '@/components/TripModificationWizard.vue'
import TripModificationStartDialog from '@/components/TripModificationStartDialog.vue'

import auth from '@/store/modules/auth'
import reservation from '@/services/reservation'
import support from '@/store/modules/support'
import customer from '@/services/customer'
import flight from '@/store/modules/flight'
import tripModification from '@/store/modules/tripModification'
import {
  Note,
  SharedList,
  ReservationDetail as ReservationDetailDTO,
  Stop,
  RequiredVehicle,
  Customer,
  Risk,
  ReservationIdentifiers,
  StagePaymentMethod,
  StagePaymentMethods,
} from '@/models/dto'

import systemParameter from '@/store/modules/systemParameter'
import {
  highestPriorityRiskTypeId,
  firstStopWithFlightRisk,
} from '@/utils/risk'
import { minutesSince, weeksSince } from '@/utils/datetime'
import { phoneRaw } from '@/utils/phone'
import { EventBus } from '@/utils/eventBus'
import {
  Page,
  PaymentMethodTypeKey,
  ReservationStatusKey,
  RiskTypeId,
  SupportTicketKey,
  SplitFeatureFlag,
  ValuePropKey
} from '@/utils/enum'
import SkeletonBox from '@/components/SkeletonBox.vue'
import { hostBaseUrl } from '@/utils/env'
import linkShortener from '@/services/linkShortener'

@Component({
  components: {
    BookingHeader,
    NotFoundLayout,
    ReservationCard,
    TwoColumnLayout,
    PaymentSuccessDialog,
    PayRemainingBalanceDialog,
    ReservationDetailContactInfo,
    ReservationDetailReview,
    ReservationDetailWarningSummary,
    ReservationDetailModificationRequested,
    SkeletonBidCard,
    SkeletonTripCollectionCard,
    ReservationDetailPostConversionDialog,
    ReservationDetailDocuments,
    ReservationDetailDriverInfo,
    ReservationDetailSupport,
    ReservationDetailPaymentOverview,
    PhoneOTCVerificationDialog,
    FlightInformationDialog,
    ReservationDetailTripSummary,
    ReservationDetailCancellationInfo,
    CharterUPValueProps,
    SkeletonBox,
    TripModificationWizard,
    TripModificationStartDialog
  },
})
export default class ReservationDetail extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly openTripModificationWizard: boolean

  windowWidth = 0
  hash = ''

  reservation: ReservationDetailDTO = null
  convertedReservations: ReservationDetailDTO[] = null
  sharedList: SharedList = []
  notFound = false
  paymentSuccessDialogIsOpen = false
  paymentReceivedMethod: PaymentMethodTypeKey = null
  payRemainingBalanceDialogIsOpen = false
  postConversionDialogIsOpen = false
  loaded = false
  support = support
  customer: Customer = null
  isPhoneVerified = false
  redirectedFromSignUp = false
  SMSOptIn = false
  isTripContactModalOpen = false
  isRiderModalOpen = false
  isVerifyPhoneModalOpen = false
  isTripModificationStartDialogOpen = false
  valuePropKeys: ValuePropKey[] = [
    ValuePropKey.BookingProtection,
    ValuePropKey.Tracking,
    ValuePropKey.LiveSupport,
    ValuePropKey.QualifiedOperators,
  ]
  page = Page.ReservationDetail
  isItineraryModificationWizardEnabled = false

  @ProvideReactive('trackingLink') trackingLink = ''
  @ProvideReactive('isWithin72HoursOfPickup') isWithin72HoursOfPickup = false

  @Watch('reservation.hash', { immediate: true })
  async updateTrackingLink() {
    if (!this.reservationHash) return
    await this.setLink()
  }

  @Watch('reservation')
  handleReservationChanged() {
    this.updateIsWithin72HoursOfPickup()
  }

  async setLink(): Promise<void> {
    try {
      const shortenedResult = await linkShortener.createShortLink(this.longLink)
      this.trackingLink = shortenedResult.data?.shortURL || this.longLink
    } catch (error) {
      this.trackingLink = this.longLink
    }
  }

  get reservationHash(): string {
    return this.reservation?.hash
  }

  get reservationIdentifiers(): ReservationIdentifiers {
    return { reservationId: this.reservationId, managedId: this.managedId }
  }

  get longLink(): string {
    return `${hostBaseUrl()}/livetracking/public/${this.reservationHash}`
  }

  get risksPerReservation(): Risk[] {
    const resRisks = []
    const uniqueRiskTypes = new Set()

    for (const stop of this.reservation?.stops || []) {
      if (!stop?.risks) {
        return resRisks
      }

      for (const risk of stop?.risks) {
        if (!uniqueRiskTypes.has(risk.riskTypeId)) {
          resRisks.push(risk)
          uniqueRiskTypes.add(risk.riskTypeId)
        }
      }

      if (!stop?.address?.risks) {
        return resRisks
      }
      for (const risk of stop?.address?.risks || []) {
        if (!uniqueRiskTypes.has(risk.riskTypeId)) {
          resRisks.push(risk)
          uniqueRiskTypes.add(risk.riskTypeId)
        }
      }
    }

    if (!this.reservation?.risks) {
      return resRisks
    }
    for (const risk of this.reservation?.risks || []) {
      if (!uniqueRiskTypes.has(risk.riskTypeId)) {
        resRisks.push(risk)
        uniqueRiskTypes.add(risk.riskTypeId)
      }
    }

    return resRisks
  }

  get highestPriorityRiskTypeIds(): number[] {
    let highPriorityRisks = []

    for (const stop of this.reservation?.stops) {
      const risks = []

      if (stop?.risks) {
        for (const risk of stop?.risks) {
          risks.push(risk)
        }
      }
      if (stop?.address?.risks) {
        for (const risk of stop?.address?.risks) {
          risks.push(risk)
        }
      }

      // Only show a risk message if its the highest priority on one of the stops
      const riskTypeIdToAdd = highestPriorityRiskTypeId(
        risks.map((risk) => risk.riskTypeId)
      )
      if (riskTypeIdToAdd) {
        highPriorityRisks.push(riskTypeIdToAdd)
      }

      // Always show flight info risks if they are present
      if (
        stop?.risks?.some(
          (risk) => risk.riskTypeId === RiskTypeId.FlightInfoMissing
        )
      ) {
        highPriorityRisks.push(RiskTypeId.FlightInfoMissing)
      }
    }

    // Always show all trip-level risks
    if (this.reservation?.risks) {
      highPriorityRisks = highPriorityRisks.concat(
        this.reservation?.risks.map((risk) => risk.riskTypeId)
      )
    }

    return highPriorityRisks
  }

  get reservationRiskExists(): boolean {
    return this.risksPerReservation?.length > 0
  }

  get reviewHash(): string {
    return this.reservation?.reservationReview?.hash || ''
  }

  get reservationId(): number {
    return this.reservation?.reservationId || null
  }

  get managedId(): string {
    return this.reservation?.managedId || ''
  }

  get insuranceUrl(): string {
    return systemParameter.insuranceUrl
  }

  get trip(): {
    stops: Stop[]
    description: string
    vehicles: RequiredVehicle[]
    passengerCount: number
    tripType: {
      label: string
    }
    tripNotes: Note[]
    stagePaymentMethods: StagePaymentMethods
    risks: Risk[]
    ada: boolean
    spab: boolean
    vehicleNeededEntireTrip: boolean
  } {
    if (!this.reservation) {
      return null
    }
    return {
      stops: this.reservation.stops,
      description: this.reservation.customerNotes,
      vehicles: this.reservation.requiredVehicles,
      passengerCount: this.reservation.passengerCount,
      tripType: {
        label: this.reservation.tripType,
      },
      tripNotes: this.reservation.tripNotesList,
      stagePaymentMethods: this.reservation.stagePaymentMethods,
      risks: this.reservation?.risks,
      ada: this.reservation?.ada,
      spab: this.reservation?.spab,
      vehicleNeededEntireTrip: this.reservation?.vehicleNeededEntireTrip,
    }
  }

  get customerId(): number {
    return this.customer?.userId
  }

  get phoneRaw(): string {
    return phoneRaw(this.customer?.phone)
  }

  get showVerifyPhone(): boolean {
    return (
      !!this.customer &&
      !this.isPhoneVerified &&
      this.redirectedFromSignUp &&
      this.SMSOptIn
    )
  }

  get balancePaymentMethods(): StagePaymentMethod[] {
    return this.trip?.stagePaymentMethods?.balancePaymentMethods ?? []
  }

  get isTripModificationWizardOpen(): boolean {
    return tripModification.isOpen
  }

  get showPendingModificationRequest(): boolean {
    return this.managedId &&
      this.isUserBookingContact &&
      this.isItineraryModificationWizardEnabled &&
      this.reservation.hasPendingModificationRequest
  }

  async retryVerify(): Promise<void> {
    try {
      await customer.updateSMS(this.customer.userId, {
        phoneNumber: phoneRaw(this.customer.phone),
      })
    } catch (err) {
      console.warn(err)
    }
  }

  async mounted(): Promise<void> {
    this.isItineraryModificationWizardEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )

    this.redirectedFromSignUp = this.$route.query.fromSignup === 'true'
    this.SMSOptIn = this.$route.query.receiveSMS === 'true'

    const customerResponse = await customer.byId(auth.userId)
    this.customer = customerResponse.data.customer
    this.isPhoneVerified = !!this.customer?.smsConfirmed

    let response
    try {
      if (this.$route.params.id) {
        await this.loadReservation()
        this.checkForOpenModals()
      } else if (this.$route.params.hash) {
        try {
          response = await reservation.byHash(this.$route.params.hash)
          if (response.data.data.reservationId) {
            this.$router.push({
              name: 'reservation-detail',
              params: { id: response.data.data.reservationId },
            })
            this.processReservation(response.data.data)
          } else {
            this.notFound = true
            this.loaded = true
          }
        } catch {
          response = null
        }
      } else {
        this.notFound = true
        return
      }
    } catch (e) {
      this.notFound = true
      console.error(e)
      this.$router.push({ name: 'reservation-index' })
      return
    }

    if (!response && !this.reservation) {
      this.notFound = true
      console.error(
        'This reservation does not exist or the user does not have access.'
      )
      this.loaded = true
      return
    }

    this.payRemainingBalanceDialogIsOpen = !!this.$route.query?.payBalance
    const postConversionDialogIsOpen = this.$route.params.confirm === 'true'
    if (postConversionDialogIsOpen) {
      await this.loadReservationsPostConvert()
    } else {
      await this.loadReservation()
    }
    this.postConversionDialogIsOpen = postConversionDialogIsOpen
    this.onResize()

    if (this.openTripModificationWizard) {
      EventBus.$emit('open-trip-modification-dialog')
    }

    EventBus.$on('refresh-reservation-detail', () => this.loadReservation())
  }

  beforeDestroy(): void {
    EventBus.$off('refresh-reservation-detail')
  }

  onResize(): void {
    this.windowWidth = window.innerWidth - 48
  }

  checkForOpenModals(): void {
    const {
      addTripContact,
      addRider,
      verifyPhoneNumber,
      addFlightInfo,
      addHotelInfo,
      addMissingInfo,
      shareLiveTracking,
    } = this.$route?.query

    if (addTripContact) {
      this.isTripContactModalOpen = true
      return
    }

    if (addRider) {
      this.isRiderModalOpen = true
      return
    }

    if (verifyPhoneNumber) {
      this.isVerifyPhoneModalOpen = true
      return
    }

    if (addFlightInfo) {
      const stop = firstStopWithFlightRisk(this.reservation?.stops)
      const flightInformation = stop?.flightInformation
      if (!!stop && !flightInformation) {
        flight.open({ flightInformation, stop })
      }
      return
    }

    if (addHotelInfo) {
      EventBus.$emit('open-hotel-info')
      return
    }

    if (addMissingInfo) {
      support.open({
        reservationId: this.reservationId,
        ticketTypeKey: SupportTicketKey.Change,
      })
      return
    }

    if (shareLiveTracking) {
      EventBus.$emit('open-share-tracking')
      return
    }
  }

  async loadReservation(): Promise<void> {
    if (this.$route.params.id) {
      const reservationResponse = await reservation.byId(
        parseInt(this.$route.params.id)
      )
      await this.processReservation(reservationResponse.data.data)
    }
  }

  async processReservation(reservation: ReservationDetailDTO): Promise<void> {
    if (!reservation?.reservationId) {
      this.notFound = true
      this.$router.push({ name: 'reservation-index' })
      return
    }

    reservation.reservationStatus =
      reservation.reservationStatus === 'hold'
        ? 'upcoming'
        : reservation.reservationStatus
    this.hash = reservation.hash
    this.reservation = reservation
    await this.getSharedList(this.reservation.reservationId)
    EventBus.$emit('refresh-contacts')
    this.loaded = true
  }

  async getSharedList(reservationId: number): Promise<void> {
    const result = await reservation.sharedList(reservationId)
    this.sharedList = result.data.sharedList
  }

  async loadReservationsPostConvert(): Promise<void> {
    const convertedReservationIds = JSON.parse(
      this.$route.params.convertedReservationIds
    )
    const resDetailPromises = convertedReservationIds.map((reservationId) =>
      reservation.byId(parseInt(reservationId))
    )
    const resDetails: ReservationDetailDTO[] = (
      await Promise.all(resDetailPromises)
    ).map((result) => result.data.data)
    if (resDetails.length) {
      this.processReservation(resDetails[0])
    }
    this.convertedReservations = resDetails
  }

  async handlePaymentSuccess(event): Promise<void> {
    await this.loadReservation()
    this.paymentReceivedMethod = event
    this.paymentSuccessDialogIsOpen = true
  }

  get isTripUpcomingOrStarted(): boolean {
    const { reservationStatus } = this.reservation || {}
    return (
      reservationStatus === ReservationStatusKey.Upcoming ||
      reservationStatus === ReservationStatusKey.Started
    )
  }

  get showReviewSection(): boolean {
    return (
      this.reviewHash &&
      this.allowReservationManagement &&
      this.minutesSinceFirstPickup >= 10 &&
      this.weeksSinceLastDropoff < 2
    )
  }

  get isUserBookingContact(): boolean {
    return auth.userId === this.reservation.customer?.customerId
  }

  get isTripContact(): boolean {
    return auth.userId === this.reservation.tripContact?.customerId
  }

  get isInBookingContactOrganization(): boolean {
    if (!auth.customerAccount.customerAccountId) {
      return false
    }
    const bookingCustomerAccountId = this.reservation?.customer
      ?.customerAccountId
    return (
      bookingCustomerAccountId === auth.customerAccount.customerAccountId ||
      auth.childCustomerAccountIds.includes(bookingCustomerAccountId)
    )
  }

  get isBookingContactOrInBookingContactOrg(): boolean {
    return this.isUserBookingContact || this.isInBookingContactOrganization
  }

  get allowReservationManagement(): boolean {
    return (
      this.isUserBookingContact ||
      this.isInBookingContactOrganization ||
      this.isTripContact
    )
  }

  get isSelfServeCancellable(): boolean {
    return this.reservation?.isSelfServeCancellable || false
  }

  get minutesSinceFirstPickup(): number {
    const stops = this.reservation?.stops
    if (!stops) {
      return 0
    }
    const firstPickup: Stop = stops.find((stop) => stop.orderIndex === 0)
    if (!firstPickup) {
      return 0
    }
    return minutesSince(this.$dayjs(firstPickup.pickupDatetime).toISOString())
  }

  get weeksSinceLastDropoff(): number {
    const stops = this.reservation?.stops
    if (!stops) {
      return 0
    }
    const lastDropoff: Stop = stops.reduce((prev, curr) =>
      prev.dropoffDatetime > curr.dropoffDatetime ? prev : curr
    )
    if (!lastDropoff) {
      return 0
    }
    return weeksSince(this.$dayjs(lastDropoff.dropoffDatetime).toISOString())
  }

  get firstPickup(): Stop {
    return this.reservation.stops.find((stop) => stop.orderIndex === 0)
  }

  updateIsWithin72HoursOfPickup(): void {
    if (!this.firstPickup) {
      this.isWithin72HoursOfPickup = false
      return
    }

    const pickupTime = this.$dayjs(this.firstPickup.pickupDatetime);
    this.isWithin72HoursOfPickup = pickupTime.subtract(72, 'hours').isBefore(this.$dayjs())
  }
}
